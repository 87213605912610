<template>
  <v-container>
    <h1>Gestion du concours cosplay</h1>
    <v-form ref="updateForm">
      <h2>Texte de présentation</h2>
      <wysiwyg v-model="text"/>
      <h2>Url document inscriptions</h2>
      <v-text-field v-model="url" :label="$t('Lien')"/>
      <h2>Gestion des liens de fichiers PDF</h2>
      <v-row class="mb-4">
        <v-col cols="6" v-for="(file,index) in files">
          <v-card>
            <v-card-title>
              <v-text-field v-model="file.nom" :label="$t('Nom du fichier')"/>
              <v-divider light/>
              <v-btn color="red" dark @click="files.splice(index,1)">X</v-btn>
            </v-card-title>
            <v-card-text>
              <v-text-field v-model="file.url" :label="$t('lien du fichier')"/>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-btn color="primary" @click="files.push({title:'',text:''})">Ajouter fichier</v-btn>
      <v-btn color="primary" @click="save">
        <v-icon>mdi-briefcase-download</v-icon>
        Sauver
      </v-btn>
    </v-form>
    <Loading :visible="isLoading"/>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {mapFields} from 'vuex-map-fields';
import Loading from '../../components/Loading';

const servicePrefix = 'GlobalParams';

export default {
  name: 'ConcoursCosplay',
  servicePrefix,
  components: {
    Loading,
  },
  data() {
    return {
      item: null,
      files: [],
      url: null,
      text: null,

    }
  },
  beforeMount() {
    this.isLoading = false;
    this.retrieve(decodeURIComponent(this.$route.name))
        .then(() => {
          this.item = this.find(decodeURIComponent(this.$route.name));
          if(this.item.value.files)
          {
            this.files = this.item.value.files;
          }
          this.url = this.item.value.url;
          this.text = this.item.value.text;
        })
        .catch(e => {
          console.error(e);
        })
  },
  computed: {
    ...mapFields('globalParam', {
      isLoading: 'isLoading'
    }),
    ...mapGetters({
      find: 'globalParam/find',
    }),
  },
  methods: {
    ...mapActions('globalParam', {
      retrieve: 'load',
      update: 'update'
    })
    ,
    save() {
      this.update(Object.assign(this.item, {
            'value':
                {
                  'text': this.text,
                  'files': this.files,
                  'url': this.url
                }
          }
      ));
    }
  }
  ,
  watch: {}
}
</script>
